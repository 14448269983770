table {
    width: 100%;
    font-size: 0.7em;
    min-width: 300px;
}

table thead tr {
    background-color: #009879;
    color: #ffffff;
    text-align: center;
    font-weight: bold;
    border-bottom-left-radius: 10px;
}

table th,
table td{
    padding: 4px 5px;
}

table tbody tr{
    border-bottom: 1px solid #dddddd;
}

table tbody tr:nth-last-of-type(even){
    background-color: #f3f3f3;
}

input{
    text-align: center;
    border: 2px solid #009879;
    border-radius: 11px;
    font-size: 1.3em;
    width: 80%;
}

footer{
    color: grey;
}
